#sticky {
  background-color: #000000 !important;
}

@media (min-width: 992px) {
  .scroll-down #header,
  .scroll-up #header {
    transform: none;
  }
  .scroll-up #sticky,
  .scroll-down #sticky {
    position: sticky;
    top: 0;
    z-index: 1021;
  }
}
@media (max-width: 992px) {
  .scroll-down #header {
    transform: translate3d(0, -100%, 0);
  }
  .scroll-up #header {
    transform: none;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
header {
  background-color: #fff;
  transition: 0.2s;
}
header:after {
  clear: both;
  display: block;
  content: "";
}
header .usps__attentie a {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 14px;
}
header .usps__attentie .fa-check {
  margin-right: 7px;
  color: #01ac3a;
  display: block;
  float: left;
}
header .topleftpanel .logo {
  display: inline-block;
  transition: 0.2s;
}
header .topmidpanel {
  color: #000000 !important;
  font-size: 21px;
}
header .topmidpanel .searchform-wrapper .btn {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  border-color: #dee2e6;
  background-color: #f1f3f5 !important;
}
header .topmidpanel .searchform-wrapper .btn i {
  color: #000000 !important;
}
header .topmidpanel .searchform-wrapper .btn:hover, header .topmidpanel .searchform-wrapper .btn:focus {
  color: #000000 !important;
  background-color: #e9ecef !important;
}
header .topmidpanel small {
  font-size: 13px;
  display: block;
  line-height: 16px;
}
header .topmidpanel small img {
  position: relative;
  top: 13px;
  margin-bottom: 15px;
}
header .toprightpanel i {
  color: #00a62b !important;
  font-size: 22px;
  position: relative;
  top: 4px;
}
header .toprightpanel a {
  color: #00a62b !important;
}
header .toprightpanel a.dropdown-toggle:after {
  display: none;
}
header .toprightpanel #wishlist-item .badge {
  top: -6px;
  z-index: 1;
}
header .toprightpanel .list-inline-item {
  position: relative;
}
header .toprightpanel .list-inline-item img {
  display: block;
  width: 25px;
  height: 25px;
  margin: 0 auto;
  margin-top: 5px;
}
header .toprightpanel .list-inline-item .label {
  font-size: 12px;
  display: block;
  line-height: 18px;
  color: #000000 !important;
}
header .toprightpanel .list-inline-item .dropdown-menu.show {
  min-width: 100px;
}
header .toprightpanel .badge {
  background: #fcc613 !important;
  position: absolute;
  margin-left: -10px;
  top: -8px;
  right: 5px;
  color: #000;
  font-size: 12px;
  line-height: 12px;
  padding: 4px 4px 4px 4px;
  min-width: 20px;
  font-family: arial, helvetica, sans-serif;
}
header .toprightpanel .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
  padding: 0 0 0 10px;
}
header .toprightpanel .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
@media (max-width: 992px) {
  header {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  header.bg-light {
    background-color: #ffffff !important;
  }
}
@media (max-width: 767px) {
  header .navbar {
    padding: 0;
  }
  header .myaccount {
    display: none;
  }
}
@media (max-width: 565px) {
  header .container {
    max-width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
  header .list-inline-item:first-child {
    margin-right: 0;
  }
  header .topleftpanel-container {
    align-items: end;
    justify-content: end;
    display: flex;
  }
  header .topleftpanel {
    text-align: center;
  }
  header #mainbar .col-auto {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
  --bs-navbar-nav-link-padding-x: 1rem;
}
@media (max-width: 1200px) {
  .navbar #mainnav .toplevelmenu__container li .nav-link {
    padding: 0.5rem;
  }
}

.navbar-brand {
  font-size: 1rem;
  padding: 0;
}

.navbar-toggler {
  border: none;
}

@media (max-width: 575px) {
  #navbar-wrapper {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  #navbar-wrapper .container {
    padding: 0 5px;
  }
  #navbar-wrapper .container .navbar-toggler {
    padding: 0.4rem 0.5rem;
  }
}

#mainnav .toplevelmenu__container {
  width: 100%;
}
#mainnav .toplevelmenu__container .nav-link {
  color: #fff;
}
#mainnav .toplevelmenu__container .nav-link i {
  color: #ffffff;
}
#mainnav .toplevelmenu__container .btn-link {
  position: absolute;
  right: 0;
  top: 4px;
  color: #fff;
}
#mainnav .toplevelmenu__container .toplevelmenu {
  margin: 0 auto;
  padding: 0;
  position: relative;
  z-index: 1021;
  list-style: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 1200px) {
  #mainnav .toplevelmenu__container .toplevelmenu__item.dropdown:hover > .dropdown-menu {
    display: block;
  }
  #mainnav .toplevelmenu__container .toplevelmenu__item.dropdown li .dropdown-menu {
    left: 100%;
    top: -3px;
  }
}
#mainnav .toplevelmenu__container .toplevelmenu .dropdown-toggle::after {
  content: "\f107";
  font-family: "FontAwesome";
  font-weight: 900;
  border: 0;
  font-size: 14px;
  position: relative;
  top: 4px;
}
#mainnav .toplevelmenu__container .toplevelmenu .has-megamenu {
  position: static;
  display: inline-block;
}
#mainnav .toplevelmenu__container .toplevelmenu .has-megamenu:first-child > a {
  background-color: #00a62b !important;
  color: #ffffff !important;
}
#mainnav .toplevelmenu__container .toplevelmenu .has-megamenu li {
  display: inline-block;
}
#mainnav .toplevelmenu__container .toplevelmenu .has-megamenu li a {
  color: #fff;
  font-size: 1.1rem;
}
#mainnav .toplevelmenu__container .toplevelmenu .has-megamenu li:hover li a:hover {
  color: #fff;
  text-decoration: underline;
}
#mainnav .toplevelmenu__container .toplevelmenu .has-megamenu .dropdown-menu {
  position: absolute;
  width: 100%;
  left: 0;
  padding: 2rem;
  margin: -1px 0 0 0;
  background-color: #000000 !important;
  border-radius: 0;
}
#mainnav .toplevelmenu__container .toplevelmenu .has-megamenu .dropdown-menu .fivecol {
  padding-bottom: 10px;
  padding-top: 10px;
}
#mainnav .toplevelmenu__container .toplevelmenu .has-megamenu .dropdown-menu .normal {
  margin-bottom: 0;
}
#mainnav .toplevelmenu__container .toplevelmenu .has-megamenu .dropdown-menu ul {
  margin: 0;
  padding: 0;
}
#mainnav .toplevelmenu__container .toplevelmenu .has-megamenu .dropdown-menu ul li {
  display: block;
  color: #fff;
  padding: 2px 0px;
}
#mainnav .toplevelmenu__container .toplevelmenu .has-megamenu .dropdown-menu ul li a {
  text-decoration: none;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.75);
}
#mainnav .toplevelmenu__container .toplevelmenu .has-megamenu .dropdown-menu ul li a:hover, #mainnav .toplevelmenu__container .toplevelmenu .has-megamenu .dropdown-menu ul li a:focus {
  text-decoration: underline;
}
#mainnav .toplevelmenu__container .toplevelmenu .has-megamenu .dropdown-menu ul li strong, #mainnav .toplevelmenu__container .toplevelmenu .has-megamenu .dropdown-menu ul li b, #mainnav .toplevelmenu__container .toplevelmenu .has-megamenu .dropdown-menu ul li a b {
  font-size: 18px;
  margin-bottom: 5px;
  display: block;
}
@media all and (min-width: 991px) {
  #mainnav .toplevelmenu__container .toplevelmenu .has-megamenu:hover:after {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    content: "";
    z-index: 1;
    pointer-events: none;
  }
  #mainnav .toplevelmenu__container .toplevelmenu .has-megamenu a {
    background-color: #000;
    position: relative;
    z-index: 1010;
  }
}

#searchcollapse .form-control {
  border: none;
  border-radius: 0;
}