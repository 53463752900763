.myaccount__menu {
  display: none;
}

.wh-wrdauth--isloggedin .myaccount__menu {
  display: block;
}
.wh-wrdauth--isloggedin aside.widget-login {
  display: none;
}
.wh-wrdauth--isloggedin .hide-loggedin {
  display: none;
}

.webshop-account__customerfieldset, .webshop-account__recentorders, .webshop-account__meta, .webshop-account__address, .webshop-account__ordercontents {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 30px;
}
.webshop-account__customerfieldlabel {
  font-weight: 500;
  margin-right: 5px;
}
.webshop-account__customerfieldlabel:after {
  content: ":";
}
.webshop-account__changelink, .webshop-account__productreviewlink {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  border-radius: 0;
  font-weight: 300;
  white-space: normal;
  background-color: #00a62b !important;
  border-color: #00a62b !important;
  color: #fff !important;
  border-color: #00a62b !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.webshop-account__changelink:hover, .webshop-account__changelinkfocus, .webshop-account__productreviewlink:hover, .webshop-account__productreviewlinkfocus {
  background-color: #00c533 !important;
  border-color: #00c533 !important;
  text-decoration: none;
  color: #fff;
}
.webshop-account__links {
  list-style: none;
}