.widget
{
  margin-bottom: 1rem;
}
  .widget--mobile
, .widget--desktop
{
  display: none;
}
@media(max-width: 767px)
{
  .widget--mobile
  {
    display: block;
  }
}
@media(min-width: 768px)
{
  .widget--desktop
  {
    display: block;
  }
}
aside
{
  border-bottom:1px solid #ddd;
  padding-bottom: 20px;
}
.widget-image
{
  margin-bottom: 20px;
}
/* productfilter(s) */
.productsgrid[data-filterconfig="null"] .widget-mobileproductfilter
{
  display:none;
}
.widget-productfilter
{
  margin: 20px 0;
  overflow: hidden;
}
.widget-productfilter--hidden
{
  display: none;
}
.productfilter__activelabel
{
  background-color: #fff;
  color:#666;
  padding: 5px 25px 5px 10px;
  font-style: italic;
  font-size: 14px;
  margin-bottom: 8px;
  margin-right: 8px;
  display: inline-block;
  position: relative;
}
.productfilter__activeremove:before
{
  content: "x";
  font-size: 18px;
  cursor: pointer;
  font-style: normal;
  color: red;
  position: absolute;
  right: 5px;
  top: 3px;
}
.productfilter:last-child
{
  padding-bottom: 0;
}
#filterSideNav .productfilter + .productfilter
{
  border-top:0;
}

#filterSideNav .widget-productfilter
{
  border-bottom:0;
  margin: 0;
}
#filterSideNav .productfilter-title,
#filterSideNav .productfilter--optionlist li label,
#filterSideNav .productfilter__activetitle,
#filterSideNav .wh-slider-holder > .wh-slider > .wh-slider-knob
{
  color:#fff;
  font-size: 14px;
}
#filterSideNav ul li
{
  border-bottom:0!important;
}
#filterSideNav .productfilter__activelabel {
  padding:5px 30px 5px 15px;
  background-color: #444;
  color: #fff;
}
#filterSideNav .productfilter__activeremove:before {
  font-size: 16px;
  color: #fff;
  right: 10px;
  top: 4px;
}
#filterSideNav ul li input[type="checkbox"] 
{
  position: relative;
  top: -4px;
}

.productfilter
{
  margin-bottom:20px;
}
.productfilter-title
{
  padding:0;
  margin: 10px 0;
  display: block;
  color:#000;
  font-size: 16px;
  font-weight: 600;
}
.productfilter-title:hover
{
  color:#000;
  text-decoration:underline ;
}
.productfilter-title[data-bs-toggle="collapse"]:after
{
  content: "\f106";
  font-family: FontAwesome;
  float: right;
  font-size: 16px;
  position: relative;
  top: -3px;
}

.productfilter-title[data-bs-toggle="collapse"].collapsed:after
{
  content: "\f107";
  font-family: FontAwesome;
  float: right;
  font-size: 18px;
}

.productfilter--optionlist
{
  list-style-type: none;
  margin: -5px 0 0 0;
  padding: 0;
}
.productfilter--optionlist li
{
  display: flex;
  align-items: center;
}
.productfilter--optionlist li label
{
  padding-left: 5px;
  margin-bottom: 0px;
  font-size: 16px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  height: 32px;
  white-space: nowrap;
}
.productfilter--select
{
  width: 100%;
  background-color:#fff;
  padding: 5px;
  border-width: 1px;
  border-color: #ccc;
  border-radius: 0;
  color: #495057;
  font-weight: 300;
}
@media(max-width: 767px)
{
  #sidebar .widget-productfilter,
  .widget-categorylist
  {
    display: none;
  }
}
a.collapsebtn[aria-expanded="false"]:before{
  content:"Filter";
}

a.collapsebtn[aria-expanded="true"]:before{
  content:"Filter inklappen";
}
.twocol .productsgrid .col-lg-4,
.twocol .productsgrid .col-sm-4,
.twocol .productsgrid .col-6,
.threecol .productsgrid .col-lg-4,
.threecol .productsgrid .col-sm-4,
.threecol .productsgrid .col-6
{
  flex: 0 0 100%;
  max-width: 100%;
}
html:not(.webshop--ishomepage) .twocol .product_listing,
html:not(.webshop--ishomepage) .threecol .product_listing
{
  margin-left: -10px;
  margin-right: -10px;
}

.widget-inlinemultiplereviews--bgwhite
{
  background-color: #fff;
  padding: 20px;
}
.widget-inlinereview__comment
{
  background-color: #eee;
  padding: 10px;
  position: relative;
  margin-bottom: 15px;
}
.widget-inlinereview__comment:after
{
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 30px;
  border-left: 10px solid transparent;
  border-top: 10px solid #eee;
  border-right: 10px solid transparent;
}
.widget-inlinereview__comment > *
{
  display: inline-block;
  margin: 0;
}
  .widget-inlinereview__comment > *:before
, .widget-inlinereview__comment > *:after
{
  display: inline-block;
  content: '"';
}
.widget-inlinereview__meta b
{
  padding-right: 10px;
}
.widget-inlinereview__more
{
  margin-top: 10px;
}

.widget-inlinereview .fr-rating
, .reviewtotals .fr-rating
{
  font-size: 30px;
  margin: 0 0 0 5px;
  font-weight: 600;
}
.widget-inlinereview .fr-recensie
, .reviewtotals .fr-recensie
{
    color: #000;
    margin-right: 5px;
}
.widget-inlinereview .fr-rating-txt,
.widget-inlinereview .fr-rating-txt a,
.reviewtotals .fr-rating-txt,
.reviewtotals .fr-rating-txt a
{
    display: block;
    margin: -5px 0 0 0;
}

.widget-fullwidthimage a
{
  display: block;
  text-decoration: none;
}
.widget-fullwidthimage img
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.category__banner
{
  background-color: #5e5045;
}
.category__banner h2,
.category__banner p
{
  color:#fff;
}

.widget-categorieslist
{
  list-style-type: none;
  margin: 10px 0 20px;
  padding: 0;
}
.widget-categorieslist li
{
  border: 1px solid #ddd;
  display: block;
  background-color: #fff;
  transition: background-color 0.2s;
}
.widget-categorieslist li + li
{
  margin-top: -1px;
}
.widget-categorieslist li:hover
{
  background-color: #f5f5f5;
}
.widget-categorieslist li:first-child
{
  border-radius: 4px 4px 0 0;
}
.widget-categorieslist li:last-child
{
  border-radius: 0 0 4px 4px;
}
.widget-categorieslist a
{
  display: block;
  color: #555;
  position: relative;
  padding: 10px 15px;
}
.widget-categorieslist a:after
{
  font-family: FontAwesome;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -10px;
  content: "\f105";
  font-size: 16px;
}
.widget-categorieslist a:hover
{
  text-decoration: none;
}

.usps__attentie {
    padding: 20px;
}
.usps__attentie ul {
  list-style: none;
  padding: 0;
  }
.usps__attentie ul li a  {
  display: flex;
  align-items: center;
  color: #000;
  margin-bottom: 5px;
}
.usps__attentie ul li .fa-check {
    margin-right: 7px;
    color: #000;
    display: block;
    float: left;
}
.usps__attentie ul li .fa-check:before
{
  display: flex;
  align-items: center;
  content: ' ';
  background-size: auto;
  height: 40px;
  width: 40px;
  background-position: center;
  background-repeat: no-repeat;
}
.usps__attentie ul li .fa-0:before
{
  background-image: url('../web/img/icon-advies.svg');
}
.usps__attentie ul li .fa-1:before
{
  background-image: url('../web/img/icon-truck.svg');
}
.usps__attentie ul li .fa-2:before
{
  background-image: url('../web/img/icon-info.svg');
}
.usps__attentie ul li .fa-3:before
{
  background-image: url('../web/img/icon-label.svg');
}

.carousel-indicators
{
  margin-bottom: 0;
}

.carousel-indicators [data-bs-target]
{
  background-color: #000;
}

.carousel-indicators .active
{
  background-color: #fcc613;
}

.widget__recentblogs
{
  padding: 80px 0px;
  margin-bottom: 30px;
}
.widget__recentblogs a p
{
  color:#333333;
}
.widget__recentblogs a.card
{
  transition: all .2s ease-in-out 0s;
}
.widget__recentblogs a.card:hover
{
  box-shadow: 0 0 20px rgba(0,0,0,.2);
  -moz-box-shadow: 0 0 20px rgba(0,0,0,.2);
  -webkit-box-shadow: 0 0 20px rgba(0,0,0,.2);
}
.widget__recentblogs a:hover
{
  text-decoration: none;
  color:#333333;
}
.widget__recentblogs h2
{
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  font-size: 18px;
  margin-top: 0;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
/* widget faq */
.widget-faq .card
{
  margin-bottom: 5px;
  border: 0;
}
.widget-faq .card-header
{
  background-color: #f0f0f0;
}

.widget-faq .card .card-header
{
  padding:0;
  margin-bottom: 0;
}
.widget-faq .btn-link
{
  color:#50505c;
  display: block;
  width: 100%;
  text-align: left;
  padding: .5rem;
}
.widget-faq .card-header .btn:after
{
  font-family: 'FontAwesome';
  content: "\f068";
  float: right;
  font-size: 14px;
}
.widget-faq .card-header .btn.collapsed:after
{
  content: "\f067";
}
.page--blog aside
{
  background-color: #fff;
  padding:20px;
  border-radius: .25rem;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
  border: 1px solid rgba(0,0,0,.125);
}
.page--blog #sidenav > ul > li.active
{
  margin-top: 20px;
}

.widget-innerlinks
{
  padding: 5px 0;
  height: 65px;
  overflow: hidden;
}
.widget-innerlinks--arrows .glide__track
{
  margin-left: 40px;
  margin-right: 40px;
}
.widget-innerlinks a
{
  color: #000;
  font-size: 1rem;
  font-weight: normal;
  white-space: normal;
  line-height: 1.2;
}
.widget-innerlinks .badge-light:hover,
.widget-innerlinks .badge-light:focus
{
  background-color: #fff;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}
.widget-innerlinks .glide__arrow
{
  background-color: #fcc613;
  border-radius: 0;
  border: 0;
  padding: 7px;
}
.widget-innerlinks .glide__arrow--next
{
  right: 0;
}

.widget__bloglist a:hover
{
  text-decoration: none!important;
}
.widget__bloglist h5
{
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
}

/* banner */
.widget-image__link
{
  color: #584a45;
}
.widget-image__link:hover
{
  color: inherit;
}
.widget-image img
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.widget-login .wrdauth__loginbutton
{
  display: block;
}

  .twocol .row:after
, .twocol .col-md-6:after
{
  content: "";
  clear: both;
  display: block;
}

.twocol .col-md-6
{
  width: 50%;
  float: left;
}

@media(max-width: 768px)
{
  .twocol .col-md-6
  {
    float: none;
    width: 100%;
  }
}

.categorylist__list,
.subcategorylist__list,
.subsubcategorylist__list
{
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
}
.categorylist__list li a:hover,
.categorylist__list li a:focus
{
  color:#333;
}
.subcategorylist__list,
.subsubcategorylist__list
{
  display: none;
}
.categorylist__listitem,
.subcategorylist__listitem,
.subsubcategorylist__listitem
{
  font-size: 15px;
}

.categorylist__listitem
{
  list-style-type: none;
  padding: 1px 0;
}
.categorylist__list > li.categorylist__listitem
{
  display: none;
}
.categorylist__list > ul > li > a 
{
  margin-bottom: 10px;
}
.categorylist__list > li.categorylist__listitem.selected
{
  display: block;
  font-size: 21px;
  font-weight: 400;
}
.subcategorylist__listitem,
.subsubcategorylist__listitem
{
  list-style-type: none;
  padding: 0 0 0 10px;
}
li.selected > .subcategorylist__list,
li.selected > .subsubcategorylist__list
{
  display: block;
  margin-top: 10px;
}
.subcategorylist__listitem.selected > a,
.subsubcategorylist__listitem.selected > a
{
  text-decoration:underline;
  font-weight: 600;
}
.categorylist__listitemlink,
.subcategorylist__listitemlink,
.subsubcategorylist__listitemlink
{
  color: #333;
}

.widget-tagcloud
{
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: 380px;
}
.widget-tagcloud ul
{
  list-style: none;
  padding: 0;
  margin: 0 -7.5px 10px -7.5px;
  overflow: hidden;
  height: 240px;
}
.widget-tagcloud li
{
  display: table-row;
}
.widget-tagcloud li:before
{
  content: '\f105';
  width: 1.5em;
  font-size: 16px;
  line-height: 24px;
  font-family: FontAwesome;
  display: table-cell;
  padding-left: 7px;
  color: #333;
}
.widget-tagcloud .less
{
  display: none;
}
.widget-tagcloud .showMore,
.widget-tagcloud .showLess
{
  cursor: pointer;
}

/* ========================================================================

   Verlanglijstje

*/

/* toon verlanglijstje verwijdering alleen op verlanglijst pagina */
html.attentie--verlanglijstje .site__content
{
  margin-top: 1.5rem;
}
html:not(.attentie--verlanglijstje) .attentie--only-verlanglijstje
{
  display:none;
}
.attentie__removefromlist label
{
  cursor:pointer;
}
.attentie__removefromlist .webshop-product__favorite
{
  visibility: hidden;
  width:0;
}
.attentie__removefromlist__text
{
  margin-left:5px;
}

.top-indicator {
    right: 0;
    top: 1rem;
    bottom: inherit;
    left: inherit;
    margin-right: 1rem;
}
.overflow {
    position: relative;
    overflow: hidden;
}

.theme__blocks
{
  padding: 50px 0;
  background-color: #eee;
  box-shadow: inset 0 0 30px rgba(0, 0, 0, .2);  
}

.zoom img {
    transition: all 0.2s linear;
}
.zoom:hover img {
    transform: scale(1.03);
}


html:not(.webshop--iscategorypage) .ordering-container,
html.webshop--ishomepage .ordering-container
{
  display: none;
}
html.webshop--istagpage .ordering-container
{
  display: block;
}