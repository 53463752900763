html.webshop--ishomepage main {
  padding: 0;
  background-color: #eee;
}
html.webshop--ishomepage .twocol p,
html.webshop--ishomepage .textcol p {
  font-size: 18px;
  line-height: 30px;
}
html.webshop--ishomepage .category__blocks {
  background-color: #56455e;
  padding: 50px 0 40px 0;
}
html.webshop--ishomepage .category__blocks .categoryitem {
  font: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  border: none;
  position: relative;
}
html.webshop--ishomepage .category__blocks .categoryitem:hover, html.webshop--ishomepage .category__blocks .categoryitem:focus {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: inherit;
}
html.webshop--ishomepage .category__blocks .categoryitem .categoryitem__image {
  text-align: center;
  margin-bottom: 10px;
}
html.webshop--ishomepage .category__blocks .categoryitem .categoryitem__image img {
  border-radius: 6px;
}
html.webshop--ishomepage .category__blocks .categoryitem__title {
  overflow: hidden;
  white-space: inherit;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0;
  color: #fff;
  padding: 20px 10px;
  left: 0;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  font-weight: 700;
  border-radius: 6px;
  text-transform: uppercase;
  font-size: 13px;
  text-shadow: 0 2px 4px #000;
  -webkit-transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
html.webshop--ishomepage .product_listing {
  position: relative;
  margin-bottom: 25px;
  margin-left: -20px;
  margin-right: -20px;
}
@media (max-width: 768px) {
  html.webshop--ishomepage .product_listing .col-sm-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (max-width: 992px) {
  html.webshop--ishomepage .usps__attentie {
    display: none;
  }
}
@media (min-width: 992px) {
  html.webshop--ishomepage .product_listing .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (min-width: 1200px) {
  html.webshop--ishomepage .product_listing .col-sm-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 1400px) {
  html.webshop--ishomepage .product_listing .col-sm-4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}