@charset "UTF-8";
webshop-product.webshop--isoutofstock .product__alwaysbeordered {
  display: none;
}

.lightbox-carousel .carousel-control-next-icon,
.lightbox-carousel .carousel-control-prev-icon {
  filter: invert(1) grayscale(100);
}

html.webshop--isproductpage body {
  background-color: #ffffff !important;
}
html.webshop--isproductpage body .modal-header .btn {
  font-size: 35px;
  line-height: inherit;
}
html.webshop--isproductpage #product__images__grid__container {
  overflow: hidden;
  height: 120px;
  position: relative;
}
html.webshop--isproductpage #product__images__grid__container .box_fade {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  overflow: hidden;
  height: 100%;
  pointer-events: none;
}
html.webshop--isproductpage #product__images__grid__container .box_fade_left {
  background-image: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0) 10%);
}
html.webshop--isproductpage #product__images__grid__container .box_fade_right {
  background-image: linear-gradient(270deg, #fff, rgba(255, 255, 255, 0) 10%);
}
html.webshop--isproductpage #product__images__grid__container .glide__slide {
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
html.webshop--isproductpage #product__images__grid__container .glide__slide img {
  opacity: 0.5;
  cursor: grab;
  max-height: 100px;
  width: auto;
  margin: 0 auto;
}
html.webshop--isproductpage #product__images__grid__container .glide__slide--active {
  border-color: #000;
}
html.webshop--isproductpage #product__images__grid__container .glide__slide--active img {
  opacity: 1;
}
html.webshop--isproductpage #product__images__grid__container .glide__arrows .glide__arrow {
  border-radius: 3px;
  background-color: #fcc613 !important;
  border: none;
  padding: 12px;
}
html.webshop--isproductpage #product__images__grid__container .glide__arrows .glide__arrow--left {
  left: 1em;
}
html.webshop--isproductpage #product__images__grid__container .glide__arrows .glide__arrow--right {
  right: 1em;
}
html.webshop--isproductpage .lightbox .btn-close svg {
  fill: #000;
}
html.webshop--isproductpage .lightbox .ratio-16x9 {
  --bs-aspect-ratio: 100%!important;
  background-color: #fff !important;
}
html.webshop--isproductpage .product__images {
  position: relative;
}
html.webshop--isproductpage .product__images .glide__slide img {
  height: 50vw;
  max-height: 600px;
  width: auto;
  margin: 0 auto;
}
html.webshop--isproductpage .product__images .glide__arrows .glide__arrow {
  border: none;
  box-shadow: none;
}
html.webshop--isproductpage .product__images .glide__arrows .glide__arrow--left {
  filter: invert(1) grayscale(100);
  left: 0;
}
html.webshop--isproductpage .product__images .glide__arrows .glide__arrow--right {
  filter: invert(1) grayscale(100);
  right: 0;
}
html.webshop--isproductpage .product__images .product__ribbons {
  width: 80px;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  pointer-events: none;
}
html.webshop--isproductpage .product__images .product__ribbons .ribbon {
  margin-bottom: 5px;
}
html.webshop--isproductpage .product__images .product__ribbons .ribbon-prijsgarantie {
  background-image: url("../../web/img/ribbons/ribbon-prijsgarantie.png");
  height: 30px;
}
html.webshop--isproductpage .product__images .product__ribbons .ribbon-eco {
  background-image: url("../../web/img/ribbons/ribbon-eco.png");
  height: 30px;
}
html.webshop--isproductpage .product__images .product__ribbons .ribbon-nieuw,
html.webshop--isproductpage .product__images .product__ribbons .ribbon-nieuw-png {
  background-image: url("../../web/img/ribbons/ribbon-nieuw.png");
  height: 30px;
}
html.webshop--isproductpage .product__images .product__ribbons .ribbon-sale {
  background-image: url("../../web/img/ribbons/ribbon-sale.png");
  height: 30px;
}

@media (min-width: 320px) and (max-width: 576px) {
  #productpage_container {
    margin-top: 20px;
  }
}
#productpage_container .badge-danger {
  background-color: #d35f5f;
  border-radius: 0;
  border-bottom-right-radius: 0.25rem;
  padding: 6px 19px 4px 19px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
#productpage_container .product__discountslogan {
  color: #8fc13e;
  font-weight: 500;
  display: block;
  border: 1px solid #8fc13e;
  margin-bottom: 10px;
  padding: 10px;
  float: right;
  width: fit-content;
  clear: both;
  margin-top: 10px;
}
#productpage_container .product__discountslogan:empty {
  display: none;
}

.product__options {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
@media (min-width: 1200px) {
  .product__options {
    top: 70px;
  }
}
.product__options .card {
  border-left: 0px solid rgba(0, 0, 0, 0.2);
  border-bottom: none;
  border-right: none;
  border-top: none;
}
@media (min-width: 565px) {
  .product__options .card .card-body {
    padding: 1.5rem;
  }
}
.product__options .card .accordion-collapse {
  scroll-margin-top: 70px;
}
@media (min-width: 565px) {
  .product__options .card .accordion-body {
    padding: 1.5rem;
  }
}
.product__options .card .accordion-body .webshop-product__options .webshop-product__option {
  clear: both;
  margin-top: 15px;
  overflow: hidden;
}
.product__options .card .accordion-button:disabled {
  opacity: 0.2;
}
.product__options .card .accordion-button:not(.collapsed) {
  background-color: #e9ecef;
  color: #000000;
}
.product__options .card .accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.product__options .card .accordion-button .accordion-header--nr {
  display: inline-block;
  margin-right: 10px;
}
.product__options .card .accordion-button .fa-check {
  margin-right: 10px;
  color: #00a62b !important;
  font-size: 26px;
}
.product__options .card .accordion-button .fa-check.text-grey {
  color: #666666 !important;
  opacity: 0.5;
}
.product__options .card .product__quantitydiscounts #promidata-price-info #priceinfo-noprint tbody tr:not(:first-child) {
  display: none;
}
.product__options .card .product__quantitydiscounts .quantitydiscounts_percentage {
  color: #00a62b !important;
  font-weight: 500;
  font-size: 14px;
}
.product__options .card .option__kleur .webshop-product__optionlabel,
.product__options .card .option__kleur .webshop-product__optionselect {
  display: none;
}
.product__options .card .promidata_stockinfo,
.product__options .card #promidata_stockinfo {
  font-size: 80%;
  line-height: 16px;
  margin-top: 5px;
  display: block;
  text-align: right;
}
.product__options .card .promidata_stockinfo.stock_green:before,
.product__options .card #promidata_stockinfo.stock_green:before {
  color: #00a62b !important;
}
.product__options .card .promidata_stockinfo.stock_orange:before,
.product__options .card #promidata_stockinfo.stock_orange:before {
  color: #fcc613 !important;
}
.product__options .card .promidata_stockinfo.stock_red:before,
.product__options .card #promidata_stockinfo.stock_red:before {
  color: #dc3545 !important;
}
.product__options .card .promidata_stockinfo:before,
.product__options .card #promidata_stockinfo:before {
  content: "●";
  font-size: 24px;
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 2px;
}
.product__options .card .promidata__orderamountcontrol .input-group-text {
  min-width: 52px;
  font-size: 14px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .product__options .card .promidata__orderamountcontrol {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 1400px) and (max-width: 1600px) {
  .product__options .card .promidata__orderamountcontrol {
    flex: 0 0 33%;
    max-width: 33%;
  }
}
.product__options .card .thumbnail-gallery .thumbnail-option {
  max-width: 89px;
  float: left;
  margin: 0 5px 5px 0;
  padding: 3px;
  border: 2px solid transparent;
}
@media (max-width: 565px) {
  .product__options .card .thumbnail-gallery .thumbnail-option {
    width: 31%;
  }
}
.product__options .card .thumbnail-gallery .thumbnail-option:hover, .product__options .card .thumbnail-gallery .thumbnail-option:focus {
  cursor: pointer;
}
.product__options .card .thumbnail-gallery .thumbnail-option img {
  width: 100%;
  height: auto;
}
.product__options .card .thumbnail-gallery .thumbnail-option.selected {
  border: 2px solid #00a62b !important;
}
.product__options .card .thumbnail-gallery .thumbnail-caption {
  font-size: 12px;
  display: block;
  line-height: 1.2;
  text-align: center;
  margin-top: 3px;
  height: 45px;
}
.product__options .card .card-footer {
  border-top: none;
  padding: 1rem;
}
.product__options .card .card-footer .product__instocktext {
  font-size: 16px;
  font-weight: 500;
}
.product__options .card .card-footer .product__instocktext i {
  color: #8fc13e;
}
.product__options .card .card-footer .text-danger a {
  color: #dc3545;
  text-decoration: underline;
}
.product__options .card .custom__offerrequest p {
  margin-bottom: 0;
  line-height: 20px;
}
.product__options .card .custom__offerrequest p b {
  color: #000000 !important;
}
.product__options h1 {
  font-size: 28px;
}
.product__options .readmore__prod {
  text-decoration: underline;
  margin-bottom: 20px;
  display: block;
  margin-top: 10px;
}
.product__options .webshop-product__priceblock {
  margin-top: 10px;
}
.product__options .webshop-product__listprice {
  font-size: 19px;
  font-weight: 400;
  color: #d35f5f;
  position: relative;
}
.product__options .webshop-product__listprice:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0px;
  width: 100%;
  height: 9px;
  background: transparent;
  border-top: 1px solid #d35f5f;
  border-radius: 0;
  transform: rotate(-11deg);
}
.product__options .webshop-product__price {
  font-size: 28px;
  font-weight: 400;
  color: #333;
}
.product__options .discountslogan {
  color: #8fc13e;
  font-weight: 500;
}
.product__options .product_promoimage > img {
  max-width: 100%;
  height: auto;
}

.product__pricelist {
  color: #000000 !important;
  border-top: 2px solid rgba(0, 0, 0, 0.125);
}
.product__pricelist .table-sm > :not(caption) > * > * {
  padding: 0.25rem 0;
}
.product__pricelist .product__selectedoptions__title,
.product__pricelist .product__selectedoptions__price {
  font-size: 13px;
  line-height: 18px;
  display: block;
}
.product__pricelist .webshop-product__price {
  margin-top: 20px;
  font-size: 21px;
}
.product__pricelist .btn-outline-secondary {
  background-color: #ffffff !important;
  border: none;
}

.min_product_tier_price {
  margin-top: 10px;
  font-size: 21px;
  font-weight: 600;
  color: #000000 !important;
}

.product__info {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 40px;
  margin-bottom: 40px;
}
.product__info .nav-link {
  color: #666666 !important;
  font-weight: 400;
  padding: 14px 10px;
}
.product__info h2 {
  font-size: 32px;
  font-weight: 400;
}
.product__info .nav-underline .nav-item .active {
  background-color: #e9ecef !important;
  color: #666666 !important;
}

.product__images__grid .slick-slide {
  border: 1px solid #c7c1bd;
  margin-bottom: 10px;
  opacity: 0.5;
  margin-right: 10px;
}
.product__images__grid .slick-slide.slick-current {
  opacity: 1;
}

@media (max-width: 577px) {
  .product__images {
    margin: 20px 0;
  }
}
.mfp-figure:after {
  background-color: #fff;
}

.product__image img {
  max-height: 100px;
  width: auto;
}

.product_social_sharing {
  position: absolute;
  font-size: 24px;
  color: #000000;
  z-index: 1;
  right: 10px;
}
.product_social_sharing label {
  cursor: pointer;
}
.product_social_sharing i {
  margin-right: 20px;
  margin-bottom: 10px;
  color: #000000;
}

.tab-container {
  overflow: hidden;
}
.tab-container .tab-section {
  margin-bottom: 50px;
}
.tab-container .tab-section > span {
  scroll-margin-top: 75px;
}
.tab-container .tab-section .table-sm td,
.tab-container .tab-section .table-sm th {
  padding: 0.4rem;
}
.tab-container .tab-section .table td,
.tab-container .tab-section .table th {
  border-top: 0;
}
.tab-container .tab-section .table-striped tbody tr:nth-of-type(odd) {
  background-color: #e9ecef !important;
}

.product__tags {
  margin-top: 10px;
  margin-bottom: 0;
  overflow: hidden;
}

.product__tag {
  background-color: #eee;
  color: #000;
  padding: 8px 12px;
  display: block;
  float: left;
  margin: 0 10px 10px 0;
  font-size: 14px;
  border: 1px solid #eee;
  transition: all 0.2s ease-in-out 0s;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-weight: 300;
  text-decoration: none;
}

.product__tag:hover,
.product__tag:focus {
  background-color: #f9f9f9;
  border-color: #f9f9f9;
  text-decoration: none;
}

.product__related {
  margin: 15px 0;
  padding: 50px 0;
}
@media (min-width: 992px) {
  .product__related .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 480px) and (max-width: 979px) {
  #productpage_container {
    padding-bottom: 40px;
  }
  .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .nav-link {
    display: block;
    padding: 0.5rem;
  }
  .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
  .product__options {
    margin-bottom: 20px !important;
  }
}
@media (min-width: 320px) and (max-width: 979px) {
  .productitem .btn {
    visibility: visible;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .product__options .card .in-decrease_amount {
    margin-right: 0;
  }
}
input.attentie__favorite {
  position: absolute !important;
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  margin: 0;
}
input.attentie__favorite + label {
  margin: 0;
}
input.attentie__favorite:checked + label > .attentie--hide-if-favorite, input.attentie__favorite:not(:checked) + label > .attentie--show-if-favorite {
  display: none;
}

html.webshop--isproductpage #ondermenu {
  margin-top: 0;
}

html.webshop--product__nonorderable .webshop-product__add {
  display: none;
}

html.product--priceonrequest .product__pricelist .product__selectedoptions__title {
  display: flex;
  flex-direction: row;
}
html.product--priceonrequest .product__pricelist .attentie-gotoorder,
html.product--priceonrequest .product__pricelist .product__selectedoptions__perpiece,
html.product--priceonrequest .product__pricelist .product__selectedoptions__price,
html.product--priceonrequest .product__pricelist .price_exvat {
  display: none;
}
html.product--priceonrequest .product__pricelist .webshop-product__price_prefix .promidata--priceperpiece {
  display: none;
}
html.product--priceonrequest .product__pricelist .webshop-product__price_prefix > div:after {
  content: "Prijs op aanvraag";
  float: right;
}
html.product--priceonrequest .product__pricelist .product__selectedoptions__amount {
  order: 1;
}
html.product--priceonrequest .product__pricelist .product__selectedoptions__amountprefix {
  order: 2;
  margin: 0 4px;
}
html.product--priceonrequest .product__pricelist .product__selectedoptions__amountprefix span {
  display: none;
}
html.product--priceonrequest .product__pricelist .product__selectedoptions__amountprefix:after {
  content: "x";
}
html.product--priceonrequest .product__pricelist .product__selectedoptions__size {
  order: 3;
}
html.product--priceonrequest .product__pricelist .product__selectedoptions__price {
  order: 4;
  margin-left: auto;
}