.bv_mainselect {
  color: #fff;
  border-radius: 0.375rem;
}
.bv_mainselect .bv_atual {
  color: #000000;
  padding: 11px 12px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.375rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  line-height: 16px;
}
.bv_mainselect .bv_atual:after {
  position: absolute;
  right: 10px;
  content: "\f107";
  font-family: FontAwesome;
}
.bv_mainselect .bv_atual.open:after {
  content: "\f106";
  font-family: FontAwesome;
}
.bv_mainselect .bv_atual > .bv_optionimage {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.bv_mainselect .bv_atual > .bv_optionimage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.bv_mainselect .bv_atual.bv_disabled {
  background-color: #e6e6e6;
  cursor: default;
}
.bv_mainselect .bv_atual.bv_separator {
  font-weight: 700;
  color: #000000;
  cursor: default;
  background-color: transparent;
}
.bv_mainselect .bv_atual.bv_background {
  background-color: #fff;
}
.bv_mainselect .bv_ul_mobile {
  width: 80%;
  position: fixed;
  top: 5%;
  left: 10%;
  border: 0px;
  height: 90%;
  margin: 0px;
  overflow-y: scroll;
  z-index: 10;
  max-height: 100%;
}
.bv_mainselect .bv_ul_mobile li {
  padding: 20px;
}
.bv_mainselect .bv_ul_bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9;
}
.bv_mainselect .bv_ul_inner {
  margin-top: 5px;
  padding: 0;
  display: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.375rem;
  width: 100%;
  z-index: 10;
  opacity: 1;
  max-height: 400px;
  overflow-x: auto;
  background-color: #fff;
}
@media (max-width: 565px) {
  .bv_mainselect .bv_ul_inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.bv_mainselect .bv_ul_inner li {
  cursor: pointer;
  list-style: none;
  color: #000000;
  padding: 12px 28px 10px 10px;
}
.bv_mainselect .bv_ul_inner li.bv_li_inner {
  display: flex;
  align-items: center;
  flex-direction: row;
  line-height: 16px;
}
.bv_mainselect .bv_ul_inner li.bv_li_inner:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.bv_mainselect .bv_ul_inner li:hover, .bv_mainselect .bv_ul_inner li:focus {
  background-color: #e9ecef;
}
.bv_mainselect .bv_ul_inner li .bv_optionimage {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.bv_mainselect .bv_ul_inner li .bv_optionimage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.bv_mainselect .bv_ul_inner li i {
  color: #000000;
  font-size: 17pt;
  margin-right: 10px;
  float: left;
  margin-top: -1px;
  margin-left: 3px;
}
.bv_mainselect .stop-scrolling {
  height: 100%;
  overflow: hidden;
}

#bv_tooltip {
  position: fixed;
  pointer-events: none;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 5px;
  border-radius: 7px;
  width: 300px;
  height: 300px;
  z-index: 10000;
}
#bv_tooltip img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bv_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9;
}