.alert:empty {
  display: none !important;
}

.webshop-checkout__form:not(.webshop-checkout--offerloyaltypoints) .webshop-checkout__loyaltysection {
  display: none !important;
}

/* The part of the form controlled by the alternative interaction needs to be hidden */
.hp-shippingmethod__formpart {
  display: none;
}

/* All the stuff that needs to be hidden when address is not NL */
.hp-shippingmethod__nlspecific--hidden {
  display: none;
}

.hp-checkout__addcode {
  height: 0;
  display: flex;
  overflow: hidden;
  transition: height 300ms;
}

input.webshop-checkout__addcouponcode {
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none;
  height: 30px;
  font: inherit;
  padding: 5px 10px;
  max-width: 300px;
  -webkit-appearance: none;
}

.webshop-checkout__addcouponbutton {
  border-radius: 0 3px 3px 0;
}

.webshop-checkout__shippingmethod.webshop-checkout--isfree .webshop-checkout__cost {
  display: none;
}

.webshop-checkout__shippingmethod.webshop-checkout--isfree .webshop-checkout__costs:after {
  content: "gratis";
  color: #8fc13e;
  font-weight: 600;
  font-size: 14px;
}
@media (max-width: 450px) {
  .webshop-checkout__shippingmethod.webshop-checkout--isfree .webshop-checkout__costs:after {
    font-size: 10px;
  }
}

.webshop-checkout__paymentmethod.webshop-checkout--isfree .webshop-checkout__costs {
  display: none;
}

.wh-form__label[for="orderform-billing_address.zip"],
.wh-form__label[for="orderform-shipping_address.zip"] {
  white-space: nowrap;
}

html.wh-wrdauth--isloggedin .hp-checkout__loginline {
  display: none;
}

html:not(.hp--showcompanyfields) .hp-checkout__inlinecompanyfields {
  display: none;
}

html:not(.hp--showrepeatedcart) .hp-checkout__repeatedcart {
  display: none;
}

.webshop-checkout__paymentmethods .wh-form__fieldline .wh-form__subfield {
  margin-left: 50px;
}

.webshop-checkout__paymentmethods .wh-form__fieldline .wh-form__subfield {
  position: relative;
  top: -33px;
}

.webshop-checkout__costs,
.webshop-listcart__total {
  padding-right: 5px;
}

#orderform-shipping_country {
  width: 155px;
  padding: 3px 5px;
  -webkit-appearance: menulist;
}
@media screen and (min-width: 320px) and (max-width: 450px) {
  #orderform-shipping_country {
    width: 135px;
    padding: 3px 5px;
    position: absolute;
    -webkit-appearance: menulist;
    left: 120px;
    top: 0;
  }
}

div[data-wh-form-group-for=shipping_country] {
  display: flex !important;
  -ms-flex-direction: inherit;
  -webkit-flex-direction: inherit;
  flex-direction: inherit;
  position: relative;
  height: 30px;
}
div[data-wh-form-group-for=shipping_country] .wh-form__label {
  min-width: 120px;
}

html.webshop--isconfirmquotepage .webshop-confirmquote {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 1rem;
}
html.webshop--isconfirmquotepage .webshop-confirmquote .webshop-checkout__shippingpayment h1 {
  font-size: 24px;
}
html.webshop--isconfirmquotepage .webshop-confirmquote .webshop-checkout__shippingpayment .wh-form__label {
  display: none;
}
html.webshop--isconfirmquotepage .webshop-confirmquote .webshop-checkout__paymentmethods {
  margin-bottom: 20px;
}
html.webshop--isconfirmquotepage .webshop-confirmquote .webshop-checkout__paymentmethods .wh-form__fieldline .wh-form__pulldown {
  width: inherit;
}
html.webshop--isconfirmquotepage .webshop-confirmquote .webshop-checkout__totalamount, html.webshop--isconfirmquotepage .webshop-confirmquote .webshop-checkout__ordertotal {
  display: none;
}

html.webshop--isrequestquotepage .hp-checkout__inclusief {
  display: none;
}
html.webshop--isrequestquotepage [data-wh-form-group-for=billing_vatnr] {
  display: none !important;
}

html.webshop--ischeckoutpage .wh-form__fieldgroup--upload {
  display: block;
}
html.webshop--ischeckoutpage .wh-form__fieldgroup--upload .wh-form__label {
  display: block;
  margin-bottom: 5px;
}
html.webshop--ischeckoutpage .wh-form__fieldgroup--upload .wh-form__fields .wh-form__fieldline {
  display: block;
}
@media (min-width: 567px) {
  html.webshop--ischeckoutpage .wh-form__fieldgroup--upload .wh-form__fields .wh-form__fieldline .wh-form__uploadfield {
    display: flex;
  }
}
html.webshop--ischeckoutpage .wh-form__fieldgroup--upload .wh-form__fields .wh-form__fieldline .wh-form__uploadfield .wh-form__uploadfieldfilename {
  min-width: 300px;
}
@media (min-width: 567px) {
  html.webshop--ischeckoutpage .wh-form__fieldgroup--upload .wh-form__fields .wh-form__fieldline .wh-form__uploadfield .wh-form__uploadfieldfilename {
    margin-right: 10px;
  }
}